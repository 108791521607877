


























import { tareaAllDto } from "@/shared/dtos/tareas/tareaAllDto";
import { Component, PropSync, Vue } from "vue-property-decorator";
@Component({
  components: {
    TareaKanban: () => import("@/views/proyectos/tareas/tareasKanban.vue"),
    TareaLista: () => import("@/views/proyectos/tareas/tareasLista.vue"),
    TareasGantt: () => import("@/views/proyectos/tareas/tareasGantt.vue"),
  },
})
export default class TareasHandler extends Vue {
  @PropSync("id_proyecto", { default: -1 }) id_proyectosync!: number;
  @PropSync("tareas", { default: [] })
  tareas_list!: tareaAllDto[];
  public view_tareas = "kanban";
}
